<template>
  <div>
    <h4>入学式 in OFURO</h4>
    <div class="small mb-3">Game</div>
    <p class="small">
      このゲームは、コロナによる自粛生活の中で誕生しました。私たちの望みは、自粛で入学式が無くなってしまった人達に、バーチャルでしか実現できない、楽しい入学式を体験してもらうことです。
      自粛期間は人との触れ合いがなく、心の距離を感じることがあります。そのような中で、バーチャルならではの「裸の付き合い」ができたら面白いのでは？といった理由で、今回の入学式の会場はお風呂になりました。<br />
      是非、自分のキャラクターを作って、入学式会場で面白いことを色々見つけてください。お風呂では先生も生徒も全員裸で対等です。この特別な空間で、みんな一緒に楽しみましょう！<br />
      制作：Duruoheng Pan（デザイン）, Emi Iwado（エンジニアリング）
    </p>
    <div class="small">
      GAME▶︎▶︎
      <a href="https://ooschool-ofuro.web.app/"
        >https://ooschool-ofuro.web.app/</a
      >
    </div>
    <div class="row">
      <div class="col-md-4">
        <img src="@/assets/ofuro1.jpg" class="img-fluid mt-3" />
      </div>
      <div class="col-md-4">
        <img src="@/assets/ofuro2.jpg" class="img-fluid mt-3" />
      </div>
      <div class="col-md-4">
        <img src="@/assets/ofuro3.jpg" class="img-fluid mt-3" />
      </div>
    </div>
  </div>
</template>

<style>
img {
  margin-bottom: 10px;
}
</style>
